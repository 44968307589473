import { Injectable, OnDestroy, Renderer2, RendererFactory2 } from '@angular/core';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class GeneralService implements OnDestroy {
  private rend: Renderer2;

  private smScrnMaxW: number = 1450;
  private smScrnMaxH: number = 1050;
  private mobScrnMaxW: number = 900;

  private resizeSub?: Subscription;

  isBigScrn: boolean;
  isMobScrn: boolean;
  forceLoad: boolean;

  scrollElTopPos: number;

  constructor(
    rendFactory: RendererFactory2
  ) { 
    this.rend = rendFactory.createRenderer(null, null);
    this.checkScrnSize();
    this.resizeSub = this.resizeInput().subscribe();
  }

  private checkScrnSize(): void {
    const winWidth = window.innerWidth,
          winHeight = window.innerHeight;

    if (
      winWidth > this.smScrnMaxW &&
      winHeight > this.smScrnMaxH
    ) {
      this.isBigScrn = true;
      this.isMobScrn = false;
      return;
    }

    this.isBigScrn = false;
    
    if (winWidth <= this.mobScrnMaxW) {
      this.isMobScrn = true;
      return;
    }

    this.isMobScrn = false;
  }

  private resizeInput(): Observable<any> {
    return fromEvent(window, 'resize').pipe(
      map(() => {
        this.checkScrnSize();
      })
    )
  }

  vhFix100(element: HTMLElement, bool?: boolean, heightProp: string = 'max-height'): void {
    if (bool || bool === undefined)
      return this.rend.setStyle(
        element, 
        heightProp, 
        `${window.innerHeight}px`
      );

    this.rend.removeStyle(element, heightProp);
  }

  scrollSnap(element?: HTMLElement, duration: number = 400, $event?: any, position?: any): void {
    if (!element && !position) return;
    
    const scrollPos = window.scrollY,
          winHeight = window.innerHeight,
          elemLoadDelay = 600,

          easeOutCubic = t => --t * t * t + 1, 

          winScroll = (pos, dur = duration) => {
            const diff = pos - scrollPos,
                  start = performance.now(),

              step = () => {
                const prog = (performance.now() - start) / dur,
                      amt = easeOutCubic(prog);

                window.scroll({
                  top: dur > 0 ? scrollPos + amt * diff : scrollPos
                });

                if (prog < 0.99) {
                  window.requestAnimationFrame(step);
                }
              };

            step();
          };

    if ($event?.type === 'click' || $event?.type === 'resize') {
      if ($event?.type === 'click') {
        this.scrollElTopPos = element.offsetTop;

        if (!this.forceLoad) {
          this.forceLoad = true;
          winScroll(window.scrollY, elemLoadDelay);

          setTimeout(() => {
            winScroll(position || element?.offsetTop);
          }, elemLoadDelay);
          
          return;
        } else {
          return winScroll(position || element?.offsetTop);
        }
      }

      return;
    }
    
    if (element) {
      const elHeight = element.offsetHeight,
            elTop = element.offsetTop,
            elBot = elTop + elHeight,
            
            scrollClass = 'scroll-snap',

            getLastEl = () => {
              return Array.from(
                element.parentElement.children
              )
              .filter((el) => 
              el.classList.contains(scrollClass)
              )
              .pop();
            },
            
            scroll = () => {
              if (!$event || $event.type === 'scroll') {
                if (!element.classList.contains(scrollClass))
                  element.classList.add(scrollClass);

                if (
                  element === getLastEl() &&
                  scrollPos > elBot - (winHeight / 2) &&
                  scrollPos < elBot
                ) {
                  return winScroll(elBot);
                }

                if (
                  elHeight > winHeight &&
                  scrollPos > elTop + (elHeight / 2) - (winHeight / 2) &&
                  scrollPos <= elBot - (winHeight / 2)
                ) {
                  return winScroll(elBot - winHeight);
                }

                if (scrollPos < elTop - (winHeight / 2))
                  return winScroll(elTop - winHeight);

                if (scrollPos < elTop + (elHeight / 2))
                  return winScroll(elTop);
              }
            };

      scroll();
    }
  }

  // Lifecycle Hooks

  ngOnDestroy(): void {
    this.resizeSub?.unsubscribe();
    this.forceLoad = false;
  }
}

export function debounce(delay: number = 300): MethodDecorator {
  return (target: any, propertyKey: string | symbol, descriptor: PropertyDescriptor) => {
    const timeoutKey = Symbol(),
          original = descriptor.value;
    
    descriptor.value = function(...args) {
      clearTimeout(this[timeoutKey]);

      this[timeoutKey] = setTimeout(() => {
        return original.apply(this, args)
      }, delay)
    }
  }
}