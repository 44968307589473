import { Component, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

import { deferState } from '@src/app/shared/animations/animations';

@Component({
  selector: 'nh-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  animations: [
    deferState
  ]
})

export class FooterComponent implements OnInit {
  constructor() { }

  // Lifecycle Hooks

  ngOnInit(): void {
  }
}
