import { Component, OnInit, AfterViewInit, AfterViewChecked, OnDestroy, ElementRef } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Subscription } from 'rxjs';
import { Entry } from 'contentful';

import { ctfConfig } from '@src/config/contentful.config';

import { AnalyticsService } from '@src/app/shared/services/fb-analytics.service';
import { GeneralService } from '@src/app/shared/services/general.service';
import { ContentService } from '@src/app/shared/services/content.service';
import { HtmlElementService } from '@src/app/shared/services/html-element.service';
import { deferState } from '@src/app/shared/animations/animations';

@Component({
  selector: 'nh-home-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss'],
  animations: [
    deferState
  ]
})
export class ServicesComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
  services: Entry<any>[] | null;
  serviceSub: Subscription;

  forceLoad: boolean;
  topPos: number;

  constructor(
    private analyticsService: AnalyticsService,
    private generalService: GeneralService,
    private contentService: ContentService,
    private HtmlElementService: HtmlElementService,
    private servicesHTML: ElementRef
  ) { }
  
  clickAnchor(elName: string, $event: any): void {
    this.HtmlElementService.getElement(elName).subscribe((el) => {
      this.analyticsService.trackInput($event);
      this.generalService.scrollSnap(el, 1000, $event);
    });
  }

  // Lifecycle Hooks

  ngOnInit(): void {
    this.serviceSub = this.contentService.getEntries(
      'services',
      'sys.createdAt'
    )
    .subscribe((data) => {
      this.services = data;
    })
  }

  ngAfterViewInit(): void {
    this.topPos = this.servicesHTML.nativeElement.offsetTop;

    this.HtmlElementService.setElement(
      'services',
      this.servicesHTML.nativeElement
    )
  }

  ngAfterViewChecked(): void {
    if (!this.forceLoad) {
      this.topPos = this.servicesHTML.nativeElement.offsetTop;

      if (this.topPos <= this.generalService.scrollElTopPos) {
        this.forceLoad = this.generalService.forceLoad;
      }
    }
  }

  ngOnDestroy(): void {
    this.serviceSub.unsubscribe();
    this.HtmlElementService.deleteElement('services');
  }

}
