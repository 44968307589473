<section id="hw-entries">
	<ul *ngIf="entries.length; else noResults" id="entries">
		<div class="grid-sizer"></div>
		<!--div class="gutter-sizer"></div-->
		<li 
			*ngFor="let entry of entries; let i = index" 
			#entryLI 
			[class]="'entry-li' + 
			((i + 1) % 2 == 0 && (i + 1) + 2 > entries.length ? ' last-second' : '') + 
			((i + 1) % 3 == 0 && (i + 1) + 3 > entries.length ? ' last-third' : '')"
		>
			<div class="entry-thumb">
				<div class="thumb-inset"></div>
			</div>
			<div class="em-wrapper">
				<a href="#" (click)="selectEntry(entry.sys.id, $event); false;">
					<img class="entry-thumb" [src]="entry.fields.thumbImage.fields.file.url" width="100%">
					<div class="entry-main">
						<h3>{{ entry.fields.title }}</h3>
					</div>
				</a>
			</div>
		</li>
	</ul>
	<ng-template #noResults>
		<div [@entriesState]="!entries.length" id="no-results">
			<p>Sorry, no projects found!</p>
		</div>
	</ng-template>
</section>
<nh-mw-entry *ngIf="entry" #entryFull [@entryState]="entryState" [entries]="entries" [entry]="entry" [ngStyle]="{'top': entryTop + 'px'}" (entryChange)="selectEntry($event)"></nh-mw-entry>