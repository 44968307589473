import { Component, OnInit, AfterViewInit, AfterViewChecked, OnDestroy, HostListener, ElementRef } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Subscription } from 'rxjs';
import { Entry } from 'contentful';

import { ContentService } from '@src/app/shared/services/content.service';
import { GeneralService, debounce } from '@src/app/shared/services/general.service';
import { HtmlElementService } from '@src/app/shared/services/html-element.service';
import { deferState } from '@src/app/shared/animations/animations';

@Component({
  selector: 'nh-home-work',
  templateUrl: './my-work.component.html',
  styleUrls: ['./my-work.component.scss'],
  animations: [
    deferState
  ]
})

export class MyWorkComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
  entries: Entry<any>[] = [];
  entry: Entry<any> | null;
  entryState: boolean;

  entriesSub: Subscription;
  entrySub: Subscription;
  entryStateSub: Subscription;

  isBigScrn: boolean;
  forceLoad: boolean;
  topPos: number;

  constructor(
    private contentService: ContentService,
    private generalService: GeneralService,
    private HtmlElementService: HtmlElementService,
    private myWorkHTML: ElementRef
  ) { }

  @debounce(1500)
  scrollSnap($event?: any): void {
    if (this.generalService.isBigScrn)
      this.generalService.scrollSnap(this.myWorkHTML.nativeElement, undefined, $event);
  }

  getEntries(): Subscription {
    return this.contentService.getEntries().subscribe(data => 
      this.entries = data
    );
  }

  closeEntry($event?: any): Subscription {
    return this.contentService.getEntry(null, undefined, false).subscribe();
  }

  clickAnchor(elName: string, $event: any): void {
    this.HtmlElementService.getElement(elName).subscribe((el) => {
      this.generalService.scrollSnap(el, 1000, $event);
    });
  }

  // Lifecycle Hooks

  ngOnInit(): void {
    this.getEntries();

    this.entriesSub = this.contentService.entries.subscribe(
      data => this.entries = data
    );

    this.entrySub = this.contentService.entry.subscribe(
      data => this.entry = data
    );

    this.entryStateSub = this.contentService.entryState.subscribe(val => {
      this.entryState = val;
    });

    this.isBigScrn = this.generalService.isBigScrn;
    this.generalService.scrollSnap(this.myWorkHTML.nativeElement);
  }

  ngAfterViewInit(): void {
    this.topPos = this.myWorkHTML.nativeElement.offsetTop;

    this.HtmlElementService.setElement(
      'my-work',
      this.myWorkHTML.nativeElement
    )
  }

  ngAfterViewChecked(): void {
    if (!this.forceLoad) {
      this.topPos = this.myWorkHTML.nativeElement.offsetTop;

      if (this.topPos <= this.generalService.scrollElTopPos) {
        this.forceLoad = this.generalService.forceLoad;
      }
    }

    if (this.isBigScrn !== this.generalService.isBigScrn) {
      if (!this.isBigScrn && this.generalService.isBigScrn)
        this.scrollSnap();

      this.isBigScrn = this.generalService.isBigScrn;
    }
  }

  ngOnDestroy(): void {
    this.getEntries().unsubscribe;
    this.entrySub.unsubscribe();
    this.entriesSub.unsubscribe();
    this.entryStateSub.unsubscribe();
    this.closeEntry().unsubscribe();
  }
}
